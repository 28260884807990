import React from 'react';
import './App.css';
import NavBar from './Component/NavBar';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Home from './Component/Home';
import About from './Component/About';
import Contact from './Component/Contact';
import Course from './Component/Courses';
import Result from './Component/Result';
class App extends React.Component {
  render() {
    return (
      <>
        <NavBar />
        <div className="app-container">
          <Router>
            <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/course">
                <Course />
              </Route>
              <Route path="/result">
                <Result />
              </Route>
              <Route path="/about">
                <About />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
            </Switch>
          </Router >
        </div>
      </>
    )
  }
}
export default App;
