import React, { useState, useEffect, useRef } from 'react';
import ReactToPrint from "react-to-print";
import PrintIcon from '@material-ui/icons/Print';
import { Form, FormControl, Button, Row, Col, Container, Table } from 'react-bootstrap';
import { useLazyQuery } from '@apollo/client';
import { STUDENTS } from '../Apollo';
const Style = {
    Container: {
        fontFamily: "Courier",
        // backgroundColor: "#F9EECA"
    },
    Table: {
        // borderStyle: "dotted double",
        border: "1px dashed black"
    }
};
export default function Result() {
    let componentRef = useRef();
    const [post, setPost] = useState({})
    const [student, setStudent] = useState({})
    const [message, setMessage] = useState('Enter your roll no and date of birth to get the result.')
    const [searchStudent, { data }] = useLazyQuery(STUDENTS, {
        fetchPolicy: "no-cache"
    });
    const search = (e) => {
        e.preventDefault();
        searchStudent({
            variables: post
        })
    }
    useEffect(() => {
        if (data && data.students && data.students.length) {
            console.log("students changed", data.students);
            setStudent(data.students[0])
        } else {
            console.log(data);
            if (data) {
                setMessage("There is no record found.");
            }
            setStudent({})
        }
    }, [data]);
    return (
        <Container>
            <h1>Result</h1>
            <Row>
                <div className="col-lg-6 col-md-9 col-sm-12 col-xs-12">
                    <Form inline>
                        <FormControl type="text" placeholder="Roll no" className="mr-sm-2" onChange={e => setPost({ ...post, roll_no: e.target.value })} />
                        <FormControl type="date" placeholder="Search" className="mr-sm-2" onChange={e => setPost({ ...post, dob: e.target.value })} />
                        <Button variant="outline-info" onClick={search} disabled={!(post.roll_no && post.dob)}>Get Result</Button>
                    </Form>
                </div>
                <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12 offset-md-3">
                    <ReactToPrint
                        documentTitle={student && student.id ? student.name : ''}
                        trigger={() => <div>{student && student.id ? <PrintIcon fontSize="large" color="default" /> : ''}</div>}
                        content={() => componentRef}
                    />
                </div>
            </Row>
            <hr />
            <Container style={Style.Container} ref={(el) => (componentRef = el)}>
                {
                    student && student.id ?
                        <div>
                            <Row>
                                <div className="col-xs-12 col-sm-9 col-md-9 col-lg-4">
                                    <Table className="table" borderless>
                                        <tbody>
                                            <tr>
                                                <td className="text-left pl-0">Name</td>
                                                <td className="text-left pl-0">{student.name}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left pl-0">Guardian's Name</td>
                                                <td className="text-left pl-0">{student.guardian_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left pl-0">Roll No</td>
                                                <td className="text-left pl-0">{student.roll_no}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Row>
                            <Row>
                                <Col>
                                    {
                                        student.enrollments ?
                                            student.enrollments.map((enrollment, index) => {
                                                let total = 0;
                                                return <div key={index}>
                                                    <p key={index.toString()}>Enrollment No:{enrollment.enrollment_no}</p>
                                                    <Table responsive="lg md sm xs" key={enrollment.id.toString()} className="text-center" style={Style.Table}>
                                                        <thead>
                                                            <tr><td colSpan="5" className="text-center">{enrollment.course && enrollment.course.name ? enrollment.course.name : ''}</td></tr>
                                                            <tr>
                                                                <th>#</th>
                                                                <th>Content</th>
                                                                <th>Internal</th>
                                                                <th>External</th>
                                                                <th>Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                enrollment.enrollment_statements && enrollment.enrollment_statements.length ?
                                                                    enrollment.enrollment_statements.map((statement, index) => {
                                                                        let internal = (statement.internal_marks ? statement.internal_marks : 0);
                                                                        let external = (statement.external_marks ? statement.external_marks : 0);
                                                                        total = total + (internal + external) / 2;
                                                                        return <tr key={index.toString()}>
                                                                            <td>{index + 1}</td>
                                                                            <td>{statement.content && statement.content.name ? statement.content.name : ''}</td>
                                                                            <td>{statement.internal_marks}</td>
                                                                            <td>{statement.external_marks}</td>
                                                                            <td>{(internal + external) / 2}</td>
                                                                        </tr>
                                                                    })
                                                                    : <p>No statements</p>
                                                            }
                                                        </tbody>
                                                        <tfoot>
                                                            <tr>
                                                                <td colSpan="4" className="text-right"><b>Grand Total</b></td>
                                                                <td><b>{total && (enrollment.enrollment_statements && enrollment.enrollment_statements.length) ? (total / enrollment.enrollment_statements.length).toFixed(2) : 'NA'}</b></td>
                                                            </tr>
                                                        </tfoot>
                                                    </Table>
                                                    <br></br>
                                                    <br></br>
                                                </div>
                                            })
                                            : <p>No enrollments found.</p>
                                    }
                                </Col>
                            </Row>
                        </div>
                        :
                        <Row>
                            <Col><p className="text-center mt-2">{message}</p></Col>
                        </Row>
                }
            </Container>
        </Container>
    )
}