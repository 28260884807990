import React from 'react';
import { Navbar, Nav } from 'react-bootstrap';
export default function NavBar(props) {
    return (
        <Navbar className="navbar-expand-lg navbar-expand-md" bg="dark" variant="dark">
            <Navbar.Brand href="/">RGCEC</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Nav className="justify-content-end">
                <Nav.Item>
                    <Nav.Link href="/course">Courses</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/result">Results</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/about">About</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link href="/contact">Contact</Nav.Link>
                </Nav.Item>
            </Nav>
        </Navbar>
    )
}