import React from 'react';
import Carousel from './CarouselSlider';

class Home extends React.Component {
    render() {
        return (
            <>
                <div className="row">
                    <div className="col-md-12">
                        <Carousel />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <h1>Home</h1>
                    </div>
                    <div className="col-md-12">
                        <p></p>
                    </div>
                </div>
            </>
        )
    }
}
export default Home;
