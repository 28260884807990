import { useState } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { useQuery } from '@apollo/client';
import { COURSES } from '../Apollo';
const Course = () => {
    const [message] = useState("Loading...")
    const { error, loading, data } = useQuery(COURSES);

    if (error) return `Error! ${error}`;
    if (loading) return 'Loader...';
    return (
        <>
            <h2>Courses</h2>
            <hr></hr>
            <Row>
                {data && data.courses ? data.courses.map((course, i) => {
                    return (
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12" key={course.id}>
                            <Card style={{ width: "18rem" }}>
                                <Card.Img variant="top" src={course.image ? '"' + course.image + '"' : '11.jpg'} />
                                <Card.Body>
                                    <Card.Title>{course.name}</Card.Title>
                                    <div>
                                        {course.contents ? course.contents.map((content, ck) => {
                                            return <div key={ck}> {" " + content.name + " "} </div>
                                        }) : ''}
                                    </div>
                                </Card.Body>
                            </Card>
                        </div>
                    )
                }) : <Col><p>{message}</p></Col>}
            </Row>
        </>
    )
}
export default Course;


/** Student
 * id
 * rollNo
 * Name
 * Gurdian name
 * DOB
 * Gender
 * mobile
 * email
 * address
 * pincode
 * qualification
 */
/** Courses
 * id
 * name
 */
/** Course Contents
 * id
 * course_id
 * name
 */

/** student_enrollments
 * id
 * enrollment_no
 * student_id
 * course_id
 * enrollment_date
 * passing_date
 */

/** student_enrollment_statement
 * id
 * enrollment_no
 * course_content_id
 * internal_marks
 * external_marks
 */


// Max => 500

// CPU 1 RAM 10 User 20 => 2way
// CPU 1 User 100 => 1000

