import { gql } from '@apollo/client';

export const COURSES = gql`
  query {
    courses{
        id
        name
        contents{
            id
            name
        }
    }
  }
`;

export const STUDENTS = gql`
  query Students($roll_no:String!, $dob:Date!){
    students(where:{roll_no:$roll_no, dob:$dob}){
        id
        name
        roll_no
        guardian_name
        enrollments{
          id
          enrollment_no
          enrollment_date
          course{
            id
            name
          }
          passing_date
          enrollment_statements{
            id
            internal_marks
            external_marks
            content{
               id
               name
            }
          }
        }
    }
  }
`;
export const SLIDES = gql`
query{
  slides{
    id
    title
    description
    image{
      id
      url
      previewUrl
    }
  }
}
`;