import { useQuery } from '@apollo/client';
import { SLIDES } from '../Apollo';
import { Carousel } from 'react-bootstrap';
import { SERVERHOST } from '../Constants/index';
const CarouselSlider = () => {
    let imgStyle = {
        height: "500px"
    }
    const { error, loading, data } = useQuery(SLIDES);

    if (error) return `Error! ${error}`;
    if (loading) return 'Loader...';

    return (
        <Carousel>
            {
                data && data.slides ?
                    data.slides.map((slide, index) => {
                        console.log(slide, slide.image.url)
                        return <Carousel.Item key={index}>
                            <img
                                className="d-block w-100"
                                src={slide.image && slide.image.url ? `${SERVERHOST}${slide.image.url}` : ''}
                                style={imgStyle}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                                <h3>{slide.title}</h3>
                                <p>{slide.description}</p>
                            </Carousel.Caption>
                        </Carousel.Item>

                    })
                    : ''
            }
        </Carousel>
    )

}
export default CarouselSlider;
